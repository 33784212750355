import { DataOptions } from 'vuetify'
import { StaffInterface } from '@/types/models/StaffInterface'
import ApiService from '@/utils/api'
import { transformParams } from '@/utils/dataOptions'

export default {
  get (options: DataOptions, search: string) {
    return ApiService.get('staff', { params: { ...transformParams(options), search } })
  },
  view (ID: number) {
    return ApiService.get(`staff/${ID}`)
  },
  add (user: StaffInterface) {
    return ApiService.post('staff', user)
  },
  edit (ID: number, user: StaffInterface) {
    return ApiService.put(`staff/${ID}`, user)
  },
  delete (ID: number) {
    return ApiService.delete(`staff/${ID}`)
  }
}
