





























import Vue from 'vue'
import { UserPaymentHistoryInterface } from '@/types/models/UserPaymentHistoryInterface'
import SubscriptionType from '@/utils/enum/SubscriptionType'
import formattingMixin from '@/mixins/formatting'
import dataTableMixin from '@/mixins/dataTable'
import UsersService from '@/services/users'

export default Vue.extend({
  name: 'UserDetailsPurchases',
  mixins: [dataTableMixin, formattingMixin],
  props: {
    userID: Number
  },
  data: () => ({
    subscriptionType: SubscriptionType,
    items: [] as UserPaymentHistoryInterface[],
    purchaseTableHeaders: [
      {
        text: 'Purchase date',
        value: 'date',
        sortable: false
      },
      {
        text: 'Purchase item',
        value: 'period',
        sortable: false
      },
      {
        text: 'Status',
        value: 'type',
        sortable: false
      }
    ]
  }),
  methods: {
    getDataFromAPI () {
      this.tableLoading = true
      UsersService.paymentHistory(this.tableOptions, this.userID).then(res => {
        if (res.data.body) {
          this.items = res.data.body.items ? res.data.body.items : []
          this.tableTotal = res.data.body.total ? res.data.body.total : 0
          this.pages = res.data.body.pages ? res.data.body.pages : 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    }
  },
  watch: {
    userID (newV, oldV): void {
      if (newV && oldV && newV !== oldV) {
        this.reloadList()
      }
    }
  }
})
