export enum UserUpgradeState {
  Init = 'init',
  Submitted = 'submitted',
  Approved = 'approved',
  Declined = 'declined'
}

export const UserUpgradeStateList = [
  UserUpgradeState.Init,
  UserUpgradeState.Submitted,
  UserUpgradeState.Approved,
  UserUpgradeState.Declined
]

export const UserUpgradeStateSelect = [
  { value: UserUpgradeState.Init, text: 'Init' },
  { value: UserUpgradeState.Submitted, text: 'Submitted' },
  { value: UserUpgradeState.Approved, text: 'Approved' },
  { value: UserUpgradeState.Declined, text: 'Declined' }
]

export default UserUpgradeState
