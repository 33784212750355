









import Vue from 'vue'

export default Vue.extend({
  name: 'ThePublicLayout',
  components: {
    TheLogo: () => import('@/components/core/TheLogo.vue')
  }
})
