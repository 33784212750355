import { ActionContext, Module } from 'vuex'

import router from '@/router'
import { AuthMutationTypes } from '@/utils/enum/store/AuthMutationTypes'
import { AuthStateInterface } from '@/types/store/modules/AuthStateInterface'
import { RootStateInterface } from '@/types/store/RootStateInterface'
import AuthService from '@/services/auth'

const getDefaultState = () => {
  return {
    isLoggedIn: false,
    accessToken: null,
    user: null
  }
}

const store: Module<AuthStateInterface, RootStateInterface> = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isAdmin (state: AuthStateInterface) {
      const role = state.user && state.user.role && state.user.role.name
      return role === 'admin'
    },
    headers (state: AuthStateInterface) {
      return { authorization: `Bearer ${state.accessToken}` }
    }
  },
  actions: {
    login (context: ActionContext<AuthStateInterface, RootStateInterface>, data: { email: string; password: string }) {
      return AuthService.login(data).then(res => {
        if (res.data && res.data.body) {
          context.commit(AuthMutationTypes.AUTHENTICATION_SUCCESS, res.data.body)
        }
        return res
      })
    },
    logout (context: ActionContext<AuthStateInterface, RootStateInterface>) {
      return AuthService.logout().then(res => {
        context.commit(AuthMutationTypes.CLEAR_AUTHENTICATION)
        return res
      })
    },
    autoLogout (context: ActionContext<AuthStateInterface, RootStateInterface>) {
      // TODO: set inactive for staff
      context.commit(AuthMutationTypes.CLEAR_AUTHENTICATION)
    }
  },
  mutations: {
    [AuthMutationTypes.AUTHENTICATION_SUCCESS] (state: AuthStateInterface, data: AuthStateInterface) {
      state.accessToken = data.token
      state.isLoggedIn = true
      state.user = data.user
    },
    [AuthMutationTypes.CLEAR_AUTHENTICATION] (state: AuthStateInterface) {
      const s: AuthStateInterface = { ...getDefaultState() }
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
      router.replace('/login')
    }
  }
}

export default store
