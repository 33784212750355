import { DataOptions } from 'vuetify'
import { transformParams } from '@/utils/dataOptions'
import ApiService from '@/utils/api'

export default {
  get (options: DataOptions) {
    return ApiService.get('search_statistic', { params: { ...transformParams(options) } })
  },
  download () {
    return ApiService.get('statistic/export', { responseType: 'blob', withCredentials: true })
  }
}
