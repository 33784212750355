export enum Interests {
  'age_play' = 'Age Play',
  'animation'= 'Animation',
  'armpits'= 'Armpits',
  'ass'= 'Ass',
  'ballbusting'= 'Ballbusting',
  'balloons'= 'Balloons',
  'barefoot_soles'= 'Barefoot/Soles',
  'bbw'= 'BBW',
  'belly_button'= 'Belly Button',
  'bhm'= 'BHM',
  'bi_sexual'= 'Bi-sexual',
  'biting'= 'Biting',
  'fluids'= 'Fluids',
  'bondage'= 'Bondage',
  'business_partnership'= 'Business partnership',
  'burping'= 'Burping',
  'cbt'= 'CBT',
  'chastity_device'= 'Chastity Device',
  'corporal_punishment'= 'Corporal Punishment',
  'coughing'= 'Coughing',
  'cuckolding'= 'Cuckolding',
  'cei'= 'CEI',
  'doll_fetish'= 'Doll Fetish',
  'down_jacket'= 'Down Jacket',
  'degradation'= 'Degradation',
  'face_sitting'= 'Face Sitting',
  'face_slapping'= 'Face Slapping',
  'farting'= 'Farting',
  'female_domination_femdom'= 'Female Domination (FemDom)',
  'film_production'= 'Film Production',
  'food_object_crush'= 'Food & Object Crush',
  'food_play'= 'Food Play',
  'foot_fetish'= 'Foot Fetish',
  'foot_pics'= 'Foot Pics',
  'gay_lesbian'= 'Gay / Lesbian',
  'glove_fetish'= 'Glove Fetish',
  'hair_fetish'= 'Hair Fetish',
  'hand_fetish'= 'Hand Fetish',
  'high_heels'= 'High Heels',
  'high_heels_worship'= 'High Heels Worship',
  'candle_play'= 'Candle Play',
  'human_ashtray'= 'Human Ashtray',
  'human_furniture'= 'Human Furniture',
  'human_punching_bag'= 'Human Punching Bag',
  'ignore_fetish'= 'Ignore Fetish',
  'inflatables'= 'Inflatables',
  'latex_leather'= 'Latex/Leather',
  'licking'= 'Licking',
  'lingerie'= 'Lingerie',
  'lipstick_fetish'= 'Lipstick Fetish',
  'littering'= 'Littering',
  'male_domination'= 'Male Domination',
  'massage'= 'Massage',
  'masturbation_instruction'= 'Masturbation Instruction',
  'modeling'= 'Modeling',
  'mouth_fetish'= 'Mouth Fetish',
  'nail_clippings'= 'Nail Clippings',
  'nose_blowing'= 'Nose Blowing',
  'nose_fetish'= 'Nose Fetish',
  'nose_picking'= 'Nose Picking',
  'panties_worship'= 'Panties Worship',
  'pedal_pumping'= 'Pedal Pumping',
  'pegging'= 'Pegging',
  'pet_play'= 'Pet Play',
  'photography'= 'Photography',
  'public_humiliation'= 'Public Humiliation',
  'scissorhold'= 'Scissorhold',
  'shoes_and_boot'= 'Shoes and Boot',
  'shoes_and_boot_worship'= 'Shoes and Boot Worship',
  'shaving'= 'Shaving',
  'size_humiliation'= 'Size Humiliation',
  'slave'= 'Slave',
  'smoking'= 'Smoking',
  'sneezing'= 'Sneezing',
  'snot'= 'Snot',
  'spanking'= 'Spanking',
  'spitting'= 'Spitting',
  'stockings_worship'= 'Stockings Worship',
  'strap_on'= 'Strap-on',
  'sweat_fetish'= 'Sweat Fetish',
  'tease_denial'= 'Tease & Denial',
  'tickling'= 'Tickling',
  'toilet_humiliation'= 'Toilet Humiliation',
  'toilet_slavery'= 'Toilet Slavery',
  'tongue_fetish'= 'Tongue Fetish',
  'trampling'= 'Trampling',
  'transsexual'= 'Transsexual',
  'second_hand_items'= 'Second-hand Items',
  'verbal_humiliation'= 'Verbal humiliation',
  'watersport'= 'Watersport',
  'wet_and_messy'= 'Wet and Messy',
  'others'= 'Others'
}

export default Interests
