import ApiService from '@/utils/api'

export default {
  login (user: { email: string; password: string }) {
    return ApiService.post('auth/login', user)
  },
  logout () {
    return ApiService.post('auth/logout')
  }
}
