import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "private" */ '@/layouts/ThePrivate.vue'),
    children: [
      {
        path: '',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '@/views/private/Search.vue')
      },
      {
        path: 'activity',
        name: 'Activity',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/private/Activity.vue')
      },
      {
        path: 'plans',
        name: 'Pricing',
        component: () => import(/* webpackChunkName: "plans" */ '@/views/private/Pricing.vue'),
        meta: {
          admin: true
        }
      },
      {
        path: 'requests',
        name: 'Requests',
        component: () => import(/* webpackChunkName: "requests" */ '@/views/private/Requests.vue')
      },
      {
        path: 'requests/:id',
        name: 'RequestEdit',
        component: () => import(/* webpackChunkName: "requests" */ '@/views/private/RequestEdit.vue')
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/private/Reports.vue')
      },
      {
        path: 'staff',
        name: 'Staff',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/private/Staff.vue'),
        meta: {
          admin: true
        }
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/private/Users.vue')
      },
      {
        path: 'statistics',
        name: 'Users search',
        component: () => import(/* webpackChunkName: "users" */ '@/views/private/Statistics.vue')
      },
      {
        path: 'verifications',
        name: 'Verifications',
        component: () => import(/* webpackChunkName: "verifications" */ '@/views/private/Verifications.vue')
      },
      {
        path: 'events',
        name: 'Events',
        component: () => import(/* webpackChunkName: "events" */ '@/views/private/Events.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/public/Login.vue'),
    meta: {
      public: true,
      onlyLoggedOut: true
    }
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "public" */ '@/layouts/ThePublic.vue'),
    children: [
      {
        path: '404',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "notfound" */ '@/views/public/NotFound.vue'),
        meta: {
          public: true
        }
      }
    ]
  },
  {
    path: '*',
    redirect: 'error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const loggedIn = store.state.auth.isLoggedIn
  if (!isPublic && !loggedIn) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }

  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
  if (loggedIn && onlyLoggedOut) {
    return next('/')
  }

  const isAdmin = to.matched.some(record => record.meta.admin)
  if (isAdmin) {
    const role = store.state.auth.user && store.state.auth.user.role && store.state.auth.user.role.name
    if (role !== 'admin') {
      return next('/')
    }
  }

  next()
})

export default router
