








































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { RootStateInterface } from '@/types/store/RootStateInterface'
import { ResponseSearchInterface } from '@/types/api/FiltersSearchInterface'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import SearchService from '@/services/search'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'SearchPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    searchLoading: false,
    search: '',
    results: null as null | ResponseSearchInterface
  }),
  computed: {
    ...mapState({
      isMobile: state => (state as RootStateInterface).mobile
    }),
    clientSite () {
      // TODO: use AWS envs
      const newOrigin = window.location.origin.replace('admin', '')
      return newOrigin.replace('://.', '://')
    }
  },
  methods: {
    updateSearch (search: string) {
      this.search = search
      this.reloadList()
    },
    getDataFromAPI () {
      if (this.search) {
        this.tableLoading = true
        SearchService.get(this.tableOptions, {
          search: this.search,
          tab: 'all'
        })
          .then(res => {
            if (res.data.body) {
              this.results = res.data.body
            }
          })
          .finally(() => {
            this.tableLoading = false
          })
      } else {
        this.results = null
        this.tableLoading = false
      }
    }
  }
})
