






























import Vue from 'vue'
import debounce from '@/utils/debounce'

export default Vue.extend({
  name: 'DebounceSearch',
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    search: undefined as string | undefined,
    toggleSearch: false,
    debounceHandler: () => {
      /* Will be replaced */
    }
  }),
  methods: {
    getDataFromAPI (): void {
      if (this.search !== undefined) {
        this.$emit('update', this.search)
      }
    }
  },
  created () {
    this.debounceHandler = debounce(this.updateSearch, 1000)
  },
  beforeMount () {
    if (this.$route.query && this.$route.query.search && (typeof this.$route.query.search === 'string')) {
      this.search = this.$route.query.search
      this.$emit('update', this.search)
    }
    if (this.$route.query && this.$route.query.id && (Number.isInteger(Number(this.$route.query.id)))) {
      this.$emit('setID', Number(this.$route.query.id))
    }
  }
})
