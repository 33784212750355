import { DataOptions } from 'vuetify'
import { FiltersVerificationsInterface } from '@/types/api/FiltersVerificationsInterface'
import { transformParams } from '@/utils/dataOptions'
import ApiService from '@/utils/api'

export default {
  get (options: DataOptions, filters: FiltersVerificationsInterface) {
    return ApiService.get('user_upgrades', { params: { ...transformParams(options), ...filters } })
  },
  approve (id: number) {
    return ApiService.post(`user_upgrades/approve/${id}`)
  },
  decline (id: number) {
    return ApiService.post(`user_upgrades/decline/${id}`)
  }
}
