import Vue from 'vue'
import moment from 'moment'

export default Vue.mixin({
  filters: {
    getFormatDate (dateS: string, format = 'DD.MM.YYYY'): string {
      if (!dateS) {
        return '-'
      }
      return moment(dateS).format(format)
    },
    getDate (dateS: string) {
      if (!dateS) {
        return '-'
      }
      return moment(dateS).format('DD/MM/YYYY')
    },
    getDateTime (dateS: string) {
      if (!dateS) {
        return '-'
      }
      return moment(dateS).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  methods: {
    getDateServerText (date: string, format = 'DD.MM.YYYY'): string {
      if (!date) {
        return ''
      }
      return moment(date, 'DDMMYYYY').format(format)
    },
    getFormattedDate (date: string, format = 'DD.MM.YYYY'): string {
      if (!date) {
        return ''
      }
      return moment(date, 'YYYY-MM-DD').format(format)
    }
  }
})
