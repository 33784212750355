import { DataOptions } from 'vuetify'
import { FiltersUsersInterface } from '@/types/api/FiltersUsersInterface'
import { transformParams } from '@/utils/dataOptions'
import ApiService from '@/utils/api'

export default {
  get (options: DataOptions, filters: FiltersUsersInterface) {
    return ApiService.get('users', { params: { ...transformParams(options), ...filters } })
  },
  view (id: number) {
    return ApiService.get(`users/${id}`)
  },
  activate (id: number) {
    return ApiService.post(`users/activate/${id}`)
  },
  deactivate (id: number) {
    return ApiService.post(`users/deactivate/${id}`)
  },
  setLifetimePremium (id: number) {
    return ApiService.post(`users/set_lifetime/${id}`)
  },
  setFree (id: number) {
    return ApiService.post(`users/set_free/${id}`)
  },
  remove (id: number) {
    return ApiService.delete(`users/${id}`)
  },
  paymentHistory (options: DataOptions, id: number) {
    return ApiService.get(`users/${id}/payment-history`, { params: { ...transformParams(options) } })
  },
  activityHistory (options: DataOptions, id: number) {
    return ApiService.get(`users/${id}/activity-history`, { params: { ...transformParams(options) } })
  },
  reportsHistory (options: DataOptions, id: number) {
    return ApiService.get(`users/${id}/report-history`, { params: { ...transformParams(options) } })
  },
  getVerificationLink (id: number) {
    return ApiService.get(`users/${id}/verification-link`)
  }
}
