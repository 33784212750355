import { render, staticRenderFns } from "./ThePrivate.vue?vue&type=template&id=0c7baff2&scoped=true&"
import script from "./ThePrivate.vue?vue&type=script&lang=ts&"
export * from "./ThePrivate.vue?vue&type=script&lang=ts&"
import style0 from "./ThePrivate.vue?vue&type=style&index=0&id=0c7baff2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c7baff2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VLayout,VMain})
