




























































import Vue from 'vue'
import { mapState } from 'vuex'
import { UserActivityHistoryInterface } from '@/types/models/UserActivityHistoryInterface'
import { RootStateInterface } from '@/types/store/RootStateInterface'
import formattingMixin from '@/mixins/formatting'
import dataTableMixin from '@/mixins/dataTable'
import UsersService from '@/services/users'

export default Vue.extend({
  name: 'UserDetailsActivity',
  mixins: [dataTableMixin, formattingMixin],
  props: {
    userID: Number
  },
  data: () => ({
    expanded: [],
    items: [] as UserActivityHistoryInterface[],
    allActivityTableHeaders: [
      {
        text: 'Date',
        value: 'date',
        sortable: false,
        isMobile: true,
        mobileText: 'Date & Time'
      },
      {
        text: 'Time',
        value: 'time',
        sortable: false
      },
      {
        text: 'Post',
        value: 'entity_name',
        sortable: false,
        isMobile: true
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        isMobile: true
      },
      {
        text: 'Agent',
        value: 'agent',
        sortable: false,
        isMobile: true
      }
    ]
  }),
  computed: {
    ...mapState({
      isMobile: state => (state as RootStateInterface).mobile
    }),
    activityTableHeaders () {
      return this.isMobile ? this.allActivityTableHeaders.filter(h => h.isMobile).map(h => {
        return {
          ...h,
          text: h.mobileText ? h.mobileText : h.text
        }
      }) : this.allActivityTableHeaders
    }
  },
  methods: {
    getDataFromAPI () {
      this.tableLoading = true
      UsersService.activityHistory(this.tableOptions, this.userID).then(res => {
        if (res.data.body) {
          this.items = res.data.body.items ? res.data.body.items : []
          this.tableTotal = res.data.body.total ? res.data.body.total : 0
          this.pages = res.data.body.pages ? res.data.body.pages : 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    }
  },
  watch: {
    userID (newV, oldV): void {
      if (newV && oldV && newV !== oldV) {
        this.reloadList()
      }
    }
  }
})
