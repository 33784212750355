































import Vue from 'vue'
import { SearchStatisticInterface } from '@/types/models/SearchStatisticInterface'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import SearchStatisticService from '@/services/searchStatistic'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'StatisticPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    headers: [
      {
        text: 'Page',
        value: 'page',
        sortable: false
      },
      {
        text: 'Request',
        value: 'request',
        sortable: false
      },
      {
        text: 'Date',
        value: 'created'
      }
    ],
    list: [] as SearchStatisticInterface[],
    status: undefined,
    exportLoading: false
  }),
  methods: {
    getDataFromAPI () {
      this.tableLoading = true
      SearchStatisticService.get(this.tableOptions)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    exportStatistic () {
      this.exportLoading = true
      SearchStatisticService.download()
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.href = url
          a.download = `search_statistic_${Date.now()}.xlsx`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .finally(() => {
          this.exportLoading = false
        })
    }
  }
})
