



















































import Vue from 'vue'
import { UserReportInterface } from '@/types/models/UserReportInterface'
import formattingMixin from '@/mixins/formatting'
import dataTableMixin from '@/mixins/dataTable'
import UsersService from '@/services/users'
import ReportsService from '@/services/reports'

export default Vue.extend({
  name: 'UserDetailsReports',
  mixins: [dataTableMixin, formattingMixin],
  props: {
    userID: Number
  },
  data: () => ({
    items: [] as UserReportInterface[],
    reportTableHeaders: [
      {
        text: 'From',
        value: 'user.username',
        sortable: false
      },
      {
        text: 'Reason',
        value: 'reason',
        sortable: false,
        cellClass: 'report-reason'
      },
      {
        text: 'Screen',
        value: 'screen',
        sortable: false,
        cellClass: 'report-screenshot'
      },
      {
        text: 'Created',
        value: 'created',
        sortable: false
      },
      {
        text: 'Modified',
        value: 'modified',
        sortable: false
      },
      {
        text: 'Resolved',
        value: 'unread',
        sortable: false
      }
    ]
  }),
  methods: {
    getDataFromAPI () {
      this.tableLoading = true
      UsersService.reportsHistory(this.tableOptions, this.userID).then(res => {
        if (res.data.body) {
          this.items = res.data.body.items ? res.data.body.items : []
          this.tableTotal = res.data.body.total ? res.data.body.total : 0
          this.pages = res.data.body.pages ? res.data.body.pages : 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    resolve (id: number) {
      ReportsService.resolve(id)
        .then(res => {
          if (res.data.body) {
            const index = this.items.findIndex(i => i.id === id)
            if (index > -1 && this.items[index]) {
              this.items[index].unread = false
              if (res.data.body.report && res.data.body.report.modified) {
                this.items[index].modified = res.data.body.report.modified
              }
            }
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    showImageModal (url: string) {
      this.$emit('showImage', url)
    }
  },
  watch: {
    userID (newV, oldV): void {
      if (newV && oldV && newV !== oldV) {
        this.reloadList()
      }
    }
  }
})
