var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('debounce-search-component',{attrs:{"loading":_vm.tableLoading},on:{"update":_vm.updateSearch,"setID":_vm.setID},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [_vm._v(" Events"),(_vm.tableTotal)?_c('span',{staticClass:"total-count"},[_vm._v(_vm._s(_vm.tableTotal))]):_vm._e()]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.tablePerPage,"options":_vm.tableOptions,"hide-default-footer":"","server-items-length":_vm.tableTotal,"loading":_vm.tableLoading,"page":_vm.currentPage,"show-expand":true,"single-expand":true,"mobile-breakpoint":0},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateTableOptions],"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.user.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.username : '')+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.created))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.created))+" ")]}},{key:"item.allday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.allday ? 'Yes' : 'No')+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.created))+" ")]}},{key:"expanded-item",fn:function(ref){
var isMobile = ref.isMobile;
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-flex',{staticClass:"request-details"},[(item.description)?_c('p',[_c('b',[_vm._v(_vm._s(item.description))])]):_vm._e(),(item.from_address)?[_c('p',[_vm._v("From:")]),_vm._l((Object.keys(item.from_address)),function(lang){return _c('v-row',{key:("event-" + (item.id) + "-from-" + lang)},[_c('v-col',{staticClass:"d-flex child-flex"},[_vm._v(_vm._s(lang)+":")]),_c('v-col',{staticClass:"d-flex child-flex"},[_vm._v(_vm._s(item.from_address[lang]))])],1)})]:_vm._e(),(item.to_address)?[_c('p',[_vm._v("To:")]),_vm._l((Object.keys(item.to_address)),function(lang){return _c('v-row',{key:("event-" + (item.id) + "-to-" + lang)},[_c('v-col',{staticClass:"d-flex child-flex"},[_vm._v(_vm._s(lang)+":")]),_c('v-col',{staticClass:"d-flex child-flex"},[_vm._v(_vm._s(item.to_address[lang]))])],1)})]:_vm._e()],2)],1)]}}])}),_c('v-row',{staticClass:"text-center align-center justify-center",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }