import { DataOptions } from 'vuetify'
import { FiltersSearchInterface } from '@/types/api/FiltersSearchInterface'
import { transformParams } from '@/utils/dataOptions'
import ApiService from '@/utils/api'

export default {
  get (options: DataOptions, filters: FiltersSearchInterface) {
    return ApiService.get('user_activity', { params: { ...transformParams(options), ...filters } })
  }
}
