import { DataOptions } from 'vuetify'
import { transformParams } from '@/utils/dataOptions'
import ApiService from '@/utils/api'
import { FiltersSearchInterface } from '@/types/api/FiltersSearchInterface'

export default {
  get (options: DataOptions, filters: FiltersSearchInterface) {
    return ApiService.get('items', { params: { ...transformParams(options), ...filters } })
  },
  changeStatus (id: number, isBanned: boolean) {
    return ApiService.put(`items/set-active/${id}`, { ban: isBanned })
  },
  view (id: number) {
    return ApiService.get(`items/${id}`)
  },
  edit (id: number, data: { title: string; description: string }) {
    return ApiService.put(`items/${id}`, data)
  },
  remove (id: number) {
    return ApiService.delete(`items/${id}`)
  },
  removeMedia (id: number) {
    return ApiService.delete(`items/media/${id}`)
  }
}
