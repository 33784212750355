var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"history-table",attrs:{"headers":_vm.purchaseTableHeaders,"items":_vm.items,"items-per-page":_vm.tablePerPage,"options":_vm.tableOptions,"hide-default-footer":"","server-items-length":_vm.tableTotal,"loading":_vm.tableLoading,"mobile-breakpoint":0,"page":_vm.currentPage},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateTableOptions],"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getFormatDate")(item.date,'YYYY/MM/DD'))+" ")]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((Number(item.subscription_plan.initialPeriod)) + " days"))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.subscriptionType[item.type])+" ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[(_vm.items.length)?_c('v-row',{staticClass:"text-center align-center justify-center inner-table-pagination",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }