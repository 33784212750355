
































































































import Vue from 'vue'
import { VFormType } from '@/types/vuetify/VFormType'
import { RoleInterface } from '@/types/models/RoleInterface'
import { StaffInterface } from '@/types/models/StaffInterface'
import EmailRegex from '@/utils/regex/email'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import RolesService from '@/services/roles'
import StaffService from '@/services/staff'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'StaffPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    EmailRegex: EmailRegex,
    saveLoading: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    list: [] as StaffInterface[],
    roles: [] as RoleInterface[],
    headers: [
      { text: 'First name', value: 'first_name' },
      { text: 'Last name', value: 'last_name' },
      { text: 'Username', value: 'username' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'role.name' },
      { text: 'Active', value: 'active' },
      { text: 'Online', value: 'online' },
      { text: 'Login at', value: 'login_at' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    editedItem: undefined as StaffInterface | undefined
  }),
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form (): VFormType {
      return this.$refs.form as VFormType
    }
  },
  created () {
    this.getRoles()
    this.editedItem = Object.assign({}, this.emptyItem())
  },
  methods: {
    getRoles () {
      RolesService.get()
        .then(res => {
          if (res.data.body) {
            this.roles = res.data.body
          }
        })
    },
    getDataFromAPI () {
      this.tableLoading = true
      StaffService.get(this.tableOptions, this.tableSearch)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 0
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    emptyItem () {
      return {
        active: true,
        email: '',
        first_name: '',
        last_name: '',
        role_id: 0,
        username: '',
        online: false
      }
    },
    setIndex (item: StaffInterface) {
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
    editItem (item: StaffInterface) {
      this.setIndex(item)
      this.dialog = true
    },
    save () {
      if (!this.form.validate()) {
        return
      }
      if (this.editedItem) {
        this.saveLoading = true
        if (this.editedIndex > -1 && this.editedItem.id) {
          const data = Object.assign({}, this.editedItem)
          delete data.is_admin
          if (data.role) {
            delete data.role
          }
          StaffService.edit(this.editedItem.id, data)
            .then(res => {
              if (res.data.body && res.data.body.user) {
                Object.assign(this.list[this.editedIndex], res.data.body.user)
              }
              this.close()
            })
            .finally(() => {
              this.saveLoading = false
            })
        } else {
          StaffService.add(this.editedItem)
            .then(res => {
              if (res.data.body && res.data.body.user) {
                this.reloadList()
              }
              this.close()
            })
            .finally(() => {
              this.saveLoading = false
            })
        }
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.emptyItem())
        this.editedIndex = -1
        this.form.reset()
      })
    },
    deleteItem (item: StaffInterface) {
      this.setIndex(item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      if (this.editedItem && this.editedItem.id) {
        StaffService.delete(this.editedItem.id).then(() => {
          this.reloadList()
          this.closeDelete()
        })
      }
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.emptyItem())
        this.editedIndex = -1
      })
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  }
})
