var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('debounce-search-component',{attrs:{"loading":_vm.tableLoading},on:{"update":_vm.updateSearch,"setID":_vm.setID},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [_vm._v(" Activity"),(_vm.tableTotal)?_c('span',{staticClass:"total-count"},[_vm._v(_vm._s(_vm.tableTotal))]):_vm._e()]},proxy:true},{key:"header-content",fn:function(){return [(_vm.user)?[_vm._v(" "+_vm._s(_vm.user.username)+" ("+_vm._s(_vm.user.email)+") ")]:_vm._e()]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.tablePerPage,"options":_vm.tableOptions,"hide-default-footer":"","server-items-length":_vm.tableTotal,"loading":_vm.tableLoading,"page":_vm.currentPage},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateTableOptions],"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.user.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.username)+" ")]}},{key:"item.entity_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity_name ? item.entity_name.replace('App\\Model\\Entity\\', '') : '-')+" ")]}},{key:"item.new",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.new))])]}},{key:"item.old",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.old))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.date))+" ")]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [(item.agent)?_c('code',[_vm._v(_vm._s(item.agent))]):_vm._e()]}}])}),_c('v-row',{staticClass:"text-center align-center justify-center",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"media_preview"},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('h4',[_vm._v("Screenshot")])],1),_c('img',{attrs:{"src":("" + _vm.$uploadsURL + _vm.dialogImg),"alt":"Report screen"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }