import { DataOptions } from 'vuetify'
import { FiltersPricingInterface } from '@/types/api/FiltersPricingInterface'
import { SubscriptionPlanInterface } from '@/types/models/SubscriptionPlanInterface'
import ApiService from '@/utils/api'
import { transformParams } from '@/utils/dataOptions'

export default {
  all () {
    return ApiService.get('subscription_plans')
  },
  get (options: DataOptions, filters: FiltersPricingInterface = {}) {
    return ApiService.get('subscription_plans', { params: { ...transformParams(options), ...filters } })
  },
  view (ID: number) {
    return ApiService.get(`subscription_plans/${ID}`)
  },
  add (plan: SubscriptionPlanInterface) {
    return ApiService.post('subscription_plans', plan)
  },
  edit (ID: number, plan: SubscriptionPlanInterface) {
    return ApiService.put(`subscription_plans/${ID}`, plan)
  },
  delete (ID: number) {
    return ApiService.delete(`subscription_plans/${ID}`)
  }
}
