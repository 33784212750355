import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

Vue.prototype.$uploadsURL = `${process.env.VUE_APP_API_BASE_URL}uploads/find?path=`

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#5D1049',
        secondary: '#E30425',
        accent: '#720D5D',
        error: '#F57C00'
      },
      dark: {
        primary: '#F70F0F',
        accent: '#690000',
        secondary: '#400000',
        error: '#FF5252',
        info: '#0d5656',
        success: '#127312',
        warning: '#FFC107',
        dark: '#1a1a1d'
      }
    }
  }
})
