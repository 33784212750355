






























































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { UserInterface } from '@/types/models/UserInterface'
import { RootStateInterface } from '@/types/store/RootStateInterface'
import Interests from '@/utils/enum/Interests'
import AccountType from '@/utils/enum/AccountType'
import UserUpgradeState from '@/utils/enum/UserUpgradeState'
import isUserOnlineMixin from '@/mixins/IsUserOnline'
import formattingMixin from '@/mixins/formatting'
import UsersService from '@/services/users'
import UserDetailsActivity from '@/components/UserDetailsActivity.vue'
import UserDetailsReports from '@/components/UserDetailsReports.vue'
import UserDetailsPurchases from '@/components/UserDetailsPurchases.vue'

enum History {
  Activity,
  Report,
  Purchase
}

export default Vue.extend({
  name: 'UserDetails',
  mixins: [isUserOnlineMixin, formattingMixin],
  props: {
    item: {
      type: Object as () => UserInterface,
      default: undefined
    }
  },
  components: {
    UserDetailsActivity,
    UserDetailsReports,
    UserDetailsPurchases
  },
  data: () => ({
    History: History,
    expanded: [],
    verificationLoading: false,
    verificationLink: null,
    showAllInterests: false,
    history: History.Activity,
    interestTranslate: Interests,
    AccountType: AccountType,
    UserUpgradeState: UserUpgradeState,
    dialog: false,
    dialogImg: null as string | null
  }),
  computed: {
    ...mapState({
      isMobile: state => (state as RootStateInterface).mobile
    }),
    address () {
      if (!this.item.user_detail.address) {
        return
      }
      return JSON.parse(this.item.user_detail.address)
    }
  },
  methods: {
    getFlagPatch (flag: string): string {
      return require(`../assets/icons/flags/${flag}.svg`)
    },
    setActive (id: number) {
      this.$emit('set-active', id)
    },
    setInactive (id: number) {
      this.$emit('set-inactive', id)
    },
    changeVerification (item: UserInterface, approve: boolean) {
      this.$emit('change-verification', item, approve)
    },
    setLifetimePremium (id: number) {
      this.$emit('set-lifetime-premium', id)
    },
    setFreePlan (id: number) {
      this.$emit('set-free-plan', id)
    },
    openRemoveDialog (user: UserInterface) {
      this.$emit('open-remove-dialog', user)
    },
    showImageModal (url: string) {
      this.dialogImg = url
      this.dialog = true
    },
    getVerificationLink () {
      if (!this.item) {
        return
      }
      this.verificationLoading = true
      UsersService.getVerificationLink(this.item.id)
        .then(res => {
          if (res.data.body && res.data.body.link) {
            this.verificationLink = res.data.body.link
          }
          this.verificationLoading = false
        })
        .catch(() => {
          this.verificationLoading = false
        })
    }
  }
})
