












import { mapState } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
  name: 'MenuMobile',
  data: () => ({
    routes: [
      {
        title: 'Users',
        to: '/users'
      },
      {
        title: 'Verifications',
        to: '/verifications'
      },
      {
        title: 'Requests',
        to: '/requests'
      },
      {
        title: 'Messages',
        to: '/messages'
      },
      {
        title: 'Staff',
        to: '/staff',
        onlyAdmin: true
      }
    ]
  }),
  computed: {
    ...mapState('auth', ['user'])
  }
})
