<template>
  <div class="login">
    <div class="login_form">
      <v-card class="login_form_card">
        <v-card-text>
          <h2>Login to Admin panel</h2>
          <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="login">
            <v-text-field v-model="user.email" label="Email" outlined required dark validate-on-blur
              :rules="[
                v => !!v || 'Email is required',
                v => EmailRegex.test(v) || 'Please enter valid email'
              ]"
            ></v-text-field>
            <v-text-field v-model="user.password" label="Password" type="password" outlined required dark
              autocomplete="off" validate-on-blur :rules="[v => !!v || 'Password is required']">
            </v-text-field>
            <v-btn class="submit_btn" :disabled="loading" @click="login">Login</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import EmailRegex from '@/utils/regex/email'

export default {
  data () {
    return {
      EmailRegex: EmailRegex,
      user: {
        email: null,
        password: null
      },
      valid: true,
      loading: false
    }
  },
  methods: {
    login () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$store.dispatch('auth/login', this.user)
          .then(res => {
            if (res.data.body && res.data.body.error) {
              return
            }
            this.$router.push('/')
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login {
  position: relative;
  background: #1a1a1d;
  padding-top: 140px;
  padding-bottom: 150px;
  height: 100%;
  @media (max-width: 1280px) {
    display: flex;
  }

  .login_form {
    max-width: 560px;
    width: 100%;
    margin: auto;

    .login_form_card {
      background: transparent;
      border: none;
      box-shadow: none;

      .v-card__text {
        padding: 0 10px;
        @media (max-width: 767px) {
          padding: 0 25px;
        }

        h2 {
          font-weight: bold;
          font-size: 30px;
          line-height: 60px;
          color: #fff;
          text-align: center;
          margin-bottom: 30px;
          font-family: 'Playfair Display', serif;
          @media (max-width: 767px) {
            margin-bottom: 0;
          }
        }

        form {
          ::v-deep.v-input {
            @media (max-width: 767px) {
              padding-top: 0;
              margin: 15px 0 0;
            }

            .v-input__control {
              .v-input__slot {
                border: 1px solid transparent;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 50px;
                backdrop-filter: blur(5px);
                margin-bottom: 0;
                margin-top: 10px;

                &:before, &:after {
                  display: none;
                }

                fieldset {
                  top: 0;
                  legend {
                    display: none;
                  }
                }

                .v-text-field__slot {
                  label {
                    font-style: italic;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 26px;
                    text-transform: capitalize;
                    padding-left: 20px;
                    top: 11px;
                    color: #fff !important;
                    padding-right: 5px;

                    &:before {
                      content: "*";
                      color: #A32324;
                    }

                    &.v-label--active {
                      transform: translateY(-35px) scale(1);
                    }
                  }
                }

                input {
                  height: 50px;
                  max-height: 50px;
                  padding: 0 20px;
                  font-size: 18px;
                }
              }
            }

            &.v-input--is-focused {
              .v-input__control {
                .v-input__slot {
                  .v-text-field__slot {
                    label {
                      color: #fff !important;
                    }
                  }
                }
              }
            }
          }

          .loginError {
            color: #ff5252;
            font-size: 16px;
            text-align: center;
            font-weight: 600;
          }

          .response_message {
            display: flex;
            justify-content: center;
            margin: 10px;

            p {
              color: #fff;
              margin: 0;
            }

            &:before {
              content: "!";
              background: red;
              width: 14px;
              min-width: 14px;
              height: 14px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
              margin-top: 3px;
              border-radius: 50%;
            }
          }

          .forgot_password {
            text-align: center;
            @media (max-width: 767px) {
              margin: 15px 0 0;
            }

            a {
              font-style: italic;
              font-weight: normal;
              font-size: 14px;
              line-height: 26px;
              text-decoration-line: underline;
              text-transform: capitalize;
              color: #FFFFFF;
              opacity: 0.6;
              padding: 0 10px;

              &:hover {
                opacity: 0.75;
              }
            }
          }

          .submit_btn {
            width: 188px;
            height: 50px;
            display: block;
            box-sizing: content-box;
            margin: 24px auto 0;
            background: #F70F0F;
            border-radius: 50px;
            font-weight: normal;
            font-size: 12px;
            line-height: 26px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #fff;
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
