













import Vue from 'vue'
import MenuMobile from '@/components/core/private/MenuMobile.vue'
import { mapState } from 'vuex'
import { RootStateInterface } from '@/types/store/RootStateInterface'

export default Vue.extend({
  name: 'ThePrivateLayout',
  components: {
    MenuMobile,
    CorePrivateAppBar: () => import('@/components/core/private/AppBar.vue'),
    CorePrivateDrawer: () => import('@/components/core/private/Drawer.vue')
  },
  data: () => ({
    expandOnHover: false
  }),
  computed: {
    ...mapState({
      isMobile: state => (state as RootStateInterface).mobile
    })
  }
})
