var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"history-table",attrs:{"headers":_vm.activityTableHeaders,"items":_vm.items,"items-per-page":_vm.tablePerPage,"options":_vm.tableOptions,"hide-default-footer":"","server-items-length":_vm.tableTotal,"loading":_vm.tableLoading,"show-expand":false,"single-expand":true,"expanded":_vm.expanded,"mobile-breakpoint":0,"page":_vm.currentPage},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateTableOptions],"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){_vm.currentPage=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [(_vm.isMobile)?_c('span',[_vm._v(_vm._s(_vm._f("getFormatDate")(item.date,'YYYY/MM/DD hh:mm a')))]):_c('span',[_vm._v(_vm._s(_vm._f("getFormatDate")(item.date,'YYYY/MM/DD')))])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getFormatDate")(item.date,'hh:mm a'))+" ")]}},{key:"item.entity_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"history-table-entity-name"},[_vm._v(" "+_vm._s(item.entity_name ? item.entity_name.split('\\').pop() : '')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.action))])]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [(item.agent)?_c('code',[_vm._v(_vm._s(item.agent))]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"value-details-container"},[_c('div',{staticClass:"value-details-col"},[_c('h3',{staticClass:"user-interests-title"},[_vm._v("Old value")]),_c('code',[_vm._v(" "+_vm._s(item.old)+" ")])]),_c('div',{staticClass:"value-details-col"},[_c('h3',{staticClass:"user-interests-title"},[_vm._v("New value")]),_c('code',[_vm._v(" "+_vm._s(item.new)+" ")])])])])])]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[(_vm.items.length)?_c('v-row',{staticClass:"text-center align-center justify-center inner-table-pagination",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }