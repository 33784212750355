var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"history-table",attrs:{"headers":_vm.reportTableHeaders,"items":_vm.items,"items-per-page":_vm.tablePerPage,"options":_vm.tableOptions,"hide-default-footer":"","server-items-length":_vm.tableTotal,"loading":_vm.tableLoading,"mobile-breakpoint":0,"page":_vm.currentPage},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateTableOptions],"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.user.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.username)+" ")]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reason)+" ")]}},{key:"item.screen",fn:function(ref){
var item = ref.item;
return [(item.screen)?_c('v-img',{staticClass:"report-image",attrs:{"src":("" + _vm.$uploadsURL + (item.screen)),"max-width":"200","max-height":"100"},on:{"click":function($event){$event.stopPropagation();return _vm.showImageModal(item.screen)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true}],null,true)}):_vm._e()]}},{key:"item.unread",fn:function(ref){
var item = ref.item;
return [(item.unread)?_c('v-btn',{staticClass:"resolve-btn",attrs:{"color":"primary","dark":"","x-small":""},on:{"click":function($event){return _vm.resolve(item.id)}}},[_vm._v(" Resolve ")]):_c('div',{staticClass:"account-status"},[_c('span',{staticClass:"account-status--active"},[_vm._v("Yes")])])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.created))+" ")]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.modified))+" ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[(_vm.items.length)?_c('v-row',{staticClass:"text-center align-center justify-center inner-table-pagination",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }