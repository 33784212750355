import Vue from 'vue'
import VueNotification from 'vue-notification'
import IdleVue from 'idle-vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

const eventsHub = new Vue()
Vue.use(IdleVue, {
  store: store,
  eventEmitter: eventsHub,
  idleTime: 30 * 60 * 1000 // 30 min
})
Vue.use(VueNotification)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
