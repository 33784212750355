<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover" :right="$vuetify.rtl"
    mobile-breakpoint="960" app width="260" v-bind="$attrs">
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props"/>
    </template>
    <v-list dense nav>
      <v-list-item>
        <TheLogo/>
      </v-list-item>
    </v-list>
    <v-list expand nav>
      <template v-for="(route, i) in routes">
        <v-list-item v-if="!route.onlyAdmin || (route.onlyAdmin && user && user.is_admin)"
                     :key="`drawer-route-${i}`" :to="route.to" active-class="current-page-link">
          <v-list-item-icon v-if="route.icon">
            <v-icon v-text="route.icon" />
          </v-list-item-icon>
          <v-list-item-content v-if="route.title">
            <v-list-item-title v-text="route.title" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CorePrivateDrawer',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TheLogo: () => import('@/components/core/TheLogo.vue')
  },
  data: () => ({
    routes: [
      {
        title: 'Search',
        to: '/'
      },
      {
        title: 'Users',
        to: '/users'
      },
      {
        title: 'Verifications',
        to: '/verifications'
      },
      {
        title: 'Reports',
        to: '/reports'
      },
      {
        title: 'Activity',
        to: '/activity'
      },
      {
        title: 'Requests',
        to: '/requests'
      },
      {
        title: 'Events',
        to: '/events'
      },
      {
        title: 'Statistics',
        to: '/statistics'
      },
      {
        title: 'Pricing',
        to: '/plans',
        onlyAdmin: true
      },
      {
        title: 'Staff',
        to: '/staff',
        onlyAdmin: true
      }
    ]
  }),
  computed: {
    ...mapState(['barColor']),
    ...mapState('auth', ['user']),
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.dispatch('setDrawer', val)
      }
    }
  }
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-item--active
    &:before
      display: none
    .v-list-item__title
      color: white
      max-width: max-content
      border-bottom: 1px solid red

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px
</style>
