var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('debounce-search-component',{ref:"table",attrs:{"loading":_vm.tableLoading},on:{"update":_vm.updateSearch,"setID":_vm.setID},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [_vm._v(" Verifications"),(_vm.tableTotal)?_c('span',{staticClass:"total-count"},[_vm._v(_vm._s(_vm.tableTotal))]):_vm._e()]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 table-main",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.tablePerPage,"options":_vm.tableOptions,"hide-default-footer":"","server-items-length":_vm.tableTotal,"page":_vm.currentPage,"loading":_vm.tableLoading,"mobile-breakpoint":0,"header-props":{ sortIcon: 'mdi-chevron-down' }},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateTableOptions],"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"header.state",fn:function(ref){
var header = ref.header;
return [_c('v-select',{staticClass:"data-table-filter",attrs:{"clearable":"","items":_vm.StatusList,"label":header.text,"append-icon":"mdi-chevron-down"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}},{key:"item.user.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.username)+" ")]}},{key:"item.user.phone",fn:function(ref){
var item = ref.item;
return [(item.user.phone)?_c('a',{attrs:{"href":("tel:" + (item.user.phone))}},[_vm._v(_vm._s(item.user.phone))]):_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"account-status"},[_c('span',{class:{
          'account-status--active': item.state === _vm.UserUpgradeState.Approved,
          'account-status--problems': item.state === _vm.UserUpgradeState.Declined,
          'account-status--pending': item.state === _vm.UserUpgradeState.Submitted,
          'account-status--init': item.state === _vm.UserUpgradeState.Init,
        }},[_vm._v(" "+_vm._s(item.state)+" ")])])]}},{key:"item.secret",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.secret)+" ")]}},{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.created))+" ")]}},{key:"item.modified",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.modified))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.state === _vm.UserUpgradeState.Submitted)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.approve(item.id)}}},[_c('v-list-item-title',[_vm._v("Approve")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.decline(item.id)}}},[_c('v-list-item-title',[_vm._v("Decline")])],1)],1)],1):_vm._e()]}}])}),_c('v-row',{staticClass:"text-center align-center justify-center",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }