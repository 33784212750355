import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import Vue from 'vue'

import store from '@/store'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_ADMIN_URL,
  timeout: 60 * 1000
})

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (store.state.auth && store.state.auth.accessToken) {
    config.headers.Authorization = `Bearer ${store.state.auth.accessToken}`
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') === -1) {
        config.params = { ...config.params, token: store.state.auth.accessToken }
      }
    }
  }
  return config
})

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response && response.data && response.data.body) {
      const invalidTokenMessage = 'Invalid or empty token.'
      if (response.data.body.message && response.data.body.message !== invalidTokenMessage) {
        Vue.notify({
          group: 'notify',
          text: response.data.body.message,
          type: response.status === 200 || response.status === 201 ? 'info' : 'error'
        })
      } else if (response.data.status === 'error' && response.data.body.error) {
        if (response.data.body.error === invalidTokenMessage) {
          if (response.config.url && !response.config.url.includes('login')) {
            store.dispatch('auth/autoLogout')
          }
        } else {
          Vue.notify({
            group: 'notify',
            text: response.data.body.error,
            type: 'error'
          })
        }
      }
    }
    return response
  },
  (error: AxiosError) => {
    if (error && error.response) {
      let text = ''
      if (error.response.data && error.response.data.body) {
        if (error.response.data.body.message) {
          text = error.response.data.body.message
        } else if (error.response.data.body.error) {
          text = error.response.data.body.error
        }
      } else if (error.response.statusText) {
        text = error.response.statusText
      }
      if (text) {
        Vue.notify({
          group: 'notify',
          text: text,
          type: 'error'
        })
      }
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
