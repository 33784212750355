




































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { FiltersUsersInterface } from '@/types/api/FiltersUsersInterface'
import { RootStateInterface } from '@/types/store/RootStateInterface'
import { UserInterface } from '@/types/models/UserInterface'
import { SubscriptionPlanInterface } from '@/types/models/SubscriptionPlanInterface'
import AccountType from '@/utils/enum/AccountType'
import { UserUpgradeState, UserUpgradeStateSelect } from '@/utils/enum/UserUpgradeState'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import UsersService from '@/services/users'
import UserUpgradesService from '@/services/userUpgrades'
import SubscriptionPlansService from '@/services/subscriptionPlans'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'
import UserDetails from '@/components/UserDetails.vue'

interface HeaderInterface {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
  isMobile?: boolean;
  mobileText?: string;
}

type VListType = {
  text: string;
  value: number | null;
}

export default Vue.extend({
  name: 'UsersPage',
  components: {
    UserDetails,
    DebounceSearchComponent
  },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    AccountType: AccountType,
    UserUpgradeState: UserUpgradeState,
    list: [] as UserInterface[],
    headersAll: [
      {
        text: 'Image',
        value: 'user_detail',
        align: 'center',
        sortable: false,
        isMobile: true,
        mobileText: 'Username'
      },
      {
        text: 'Username',
        value: 'username',
        isMobile: true,
        mobileText: ''
      },
      {
        text: 'Email',
        value: 'email',
        isMobile: true,
        mobileText: 'Email'
      },
      {
        text: 'Last login',
        value: 'login_at'
      },
      {
        text: 'Register date',
        value: 'created'
      },
      {
        text: 'Country',
        value: 'user_detail.address',
        sortable: false
      },
      {
        text: 'Account status',
        value: 'account_status',
        sortable: false
      },
      {
        text: 'Verification status',
        value: 'verification_status',
        sortable: false
      },
      {
        text: 'Current plan',
        value: 'user_subscriptions',
        sortable: false
      },
      {
        text: 'Options',
        value: 'actions',
        sortable: false
      }
    ] as HeaderInterface[],
    userTypes: [],
    accountStatus: undefined,
    accountStatusList: ['active', 'pending', 'banned', 'reported'],
    verificationStatus: undefined,
    verificationStatusList: UserUpgradeStateSelect,
    currentPlan: undefined,
    planList: [] as VListType[],
    selectedUser: null as null | UserInterface,
    showRemoveDialog: false,
    removing: false,
    disabledChangePlanId: null as null | number,
    expendedTable: []
  }),
  computed: {
    ...mapState({
      isMobile: state => (state as RootStateInterface).mobile
    }),
    headers () {
      if (this.isMobile) {
        return this.headersAll.filter(h => h.isMobile).map(h => {
          return {
            ...h,
            text: h.mobileText ? h.mobileText : ''
          }
        })
      }
      return this.headersAll
    }
  },
  filters: {
    getCountry: function (value: string) {
      if (!value) return ''
      const address = JSON.parse(value)
      if (!address.en) {
        return value
      }
      const parts = address.en.split(',')
      if (!parts.length) {
        return address.en
      }
      return parts[parts.length - 1]
    }
  },
  methods: {
    getFlagPatch (flag: string): string {
      return require(`../../assets/icons/flags/${flag}.svg`)
    },
    getDataFromAPI () {
      this.tableLoading = true
      const params = {
        types: this.userTypes
      } as FiltersUsersInterface
      if (this.tableSearch) {
        params.search = this.tableSearch
      }
      if (this.accountStatus) {
        params.account_status = this.accountStatus
      }
      if (this.verificationStatus) {
        params.verification_status = this.verificationStatus
      }
      if (this.currentPlan) {
        params.current_plan = this.currentPlan
      }
      if (this.ID) {
        params.id = this.ID
      }
      UsersService.get(this.tableOptions, params)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    setActive (id: number) {
      UsersService.activate(id)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex(i => i.id === id)
            if (index > -1 && this.list[index]) {
              this.list[index].user_reports = []
              this.list[index].active = true
            }
          }
        })
    },
    setInactive (id: number) {
      UsersService.deactivate(id)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex(i => i.id === id)
            if (index > -1 && this.list[index]) {
              this.list[index].active = false
            }
          }
        })
    },
    changeVerification (item: UserInterface, approve: boolean) {
      if (item.user_upgrades && item.user_upgrades.length) {
        // Only one active or pending verification request is allowed
        const id = item.user_upgrades[0].id
        UserUpgradesService[approve ? 'approve' : 'decline'](id)
          .then(res => {
            if (res.data.body) {
              const index = this.list.findIndex(i => i.id === item.id)
              if (index > -1 && res.data.body.state) {
                if (this.list[index] && this.list[index].user_upgrades) {
                  this.list[index].user_upgrades[0].state = res.data.body.state
                }
              }
            }
          })
      }
    },
    setLifetimePremium (id: number) {
      if (this.disabledChangePlanId === id) {
        return
      }
      this.disabledChangePlanId = id
      UsersService.setLifetimePremium(id)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex(i => i.id === id)
            if (index > -1 && this.list[index]) {
              const userSubscriptions = []
              userSubscriptions.push(res.data.body)
              this.list[index].user_subscriptions = userSubscriptions
            }
          }
        })
        .finally(() => {
          this.disabledChangePlanId = null
        })
    },
    setFreePlan (id: number) {
      if (this.disabledChangePlanId === id) {
        return
      }
      this.disabledChangePlanId = id
      UsersService.setFree(id)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex(i => i.id === id)
            if (index > -1 && this.list[index]) {
              this.list[index].user_subscriptions = []
            }
          }
        })
        .finally(() => {
          this.disabledChangePlanId = null
        })
    },
    openRemoveDialog (user: UserInterface) {
      this.selectedUser = user
      this.showRemoveDialog = true
    },
    removeUser (id: number) {
      this.removing = true
      UsersService.remove(id)
        .then(res => {
          if (res.data.body) {
            this.getDataFromAPI()
          }
        })
        .finally(() => {
          this.removing = false
          this.showRemoveDialog = false
        })
    }
  },
  beforeMount () {
    SubscriptionPlansService.all()
      .then(res => {
        if (res.data.body && res.data.body.items && res.data.body.items.length) {
          const planList = [] as VListType[]
          res.data.body.items.forEach((plan: SubscriptionPlanInterface) => {
            if (plan.title && plan.title.en) {
              planList.push({
                text: plan.title.en,
                value: plan.id
              })
            }
          })
          this.planList = planList
        }
      })
      .finally(() => {
        this.tableLoading = false
      })
  },
  watch: {
    userTypes: {
      deep: true,
      handler (): void {
        this.reloadList()
      }
    },
    accountStatus (newV, oldV) {
      if (newV !== oldV) {
        this.reloadList()
      }
    },
    verificationStatus (newV, oldV) {
      if (newV !== oldV) {
        this.reloadList()
      }
    },
    currentPlan (newV, oldV) {
      if (newV !== oldV) {
        this.reloadList()
      }
    }
  }
})
