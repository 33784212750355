import Vue from 'vue'
import moment from 'moment'

export default Vue.mixin({
  methods: {
    isUserOnline (user): boolean {
      if (user.is_online) {
        return true
      }
      let isOnline = false
      if (user && user.online && user.login_at) {
        const current = new Date()
        const offset = current.getTimezoneOffset()
        const diffMinutes = Math.ceil((current.getTime() - new Date(user.login_at).getTime()) / (1000 * 60)) + offset
        isOnline = diffMinutes <= 30
      }
      return isOnline
    },
    userOnlineFromNow (user): string {
      return user.login_at ? moment(user.login_at).fromNow() : ''
    }
  }
})
