
















































import Vue from 'vue'
import { RequestInterface, RequestImageInterface } from '@/types/models/RequestInterface'
import { VFormType } from '@/types/vuetify/VFormType'
import RequestsService from '@/services/requests'

export default Vue.extend({
  name: 'RequestEditPage',
  data: () => ({
    isLoading: true,
    isSaving: false,
    isRemoving: false,
    item: null as RequestInterface | null,
    images: [] as Array<RequestImageInterface>,
    videos: [] as Array<RequestImageInterface>,
    form: {
      title: '',
      description: ''
    }
  }),
  computed: {
    requestForm (): VFormType {
      return this.$refs.requestForm as VFormType
    }
  },
  methods: {
    isVideoFormat (media: RequestImageInterface) {
      if (!media || !media.type) {
        return false
      }
      return media.type.startsWith('video')
    },
    removeMedia (index: number, media: RequestImageInterface) {
      if (media.id) {
        this.isRemoving = true
        RequestsService.removeMedia(media.id)
          .then(() => {
            if (this.item && this.item.item_images && this.item.item_images.length) {
              this.item.item_images.splice(index, 1)
            }
          })
          .finally(() => {
            this.isRemoving = false
          })
      }
    },
    edit () {
      if (!this.item || !this.requestForm.validate()) {
        return
      }
      this.isSaving = true
      RequestsService.edit(this.item.id, this.form)
        .finally(() => {
          this.isSaving = false
        })
    },
    details (id: number | string) {
      if (typeof id === 'string') {
        id = parseInt(id)
      }
      if (id) {
        this.isLoading = true
        this.images = []
        this.videos = []
        RequestsService.view(id)
          .then(res => {
            if (res.data.body) {
              this.item = res.data.body
              this.form = {
                title: res.data.body.title,
                description: res.data.body.description
              }
              if (res.data.body.item_images && res.data.body.item_images.length) {
                res.data.body.item_images.forEach((media: RequestImageInterface) => {
                  if (this.isVideoFormat(media)) {
                    this.videos.push(media)
                  } else {
                    this.images.push(media)
                  }
                })
              }
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    }
  },
  watch: {
    '$route.params.id' (newV, oldV) {
      if (oldV && oldV !== newV) {
        this.details(newV)
      }
    }
  },
  created () {
    if (this.$route.params.id && !!parseInt(this.$route.params.id)) {
      this.details(this.$route.params.id)
    }
  }
})
