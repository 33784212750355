export enum SubscriptionType {
  expired = 'Expired',
  canceled = 'Canceled',
  unsubscribed = 'Unsubscribed',
  'new-purchase' = 'New purchase',
  'renewal-failure' = 'Renewal failure',
  'auto-recurring' = 'Auto recurring'
}

export default SubscriptionType
