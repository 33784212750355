import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/modules/auth'
import { RootStateInterface } from '@/types/store/RootStateInterface'
import Media from '@/utils/enum/Media'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    drawer: null,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    mobile: window.innerWidth <= Media.Mobile
  } as RootStateInterface,
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_MOBILE (state, payload) {
      state.mobile = payload
    }
  },
  actions: {
    setDrawer ({ commit }, payload) {
      commit('SET_DRAWER', payload)
    },
    setMobile ({ commit }, payload) {
      commit('SET_MOBILE', payload)
    },
    clearAll ({ dispatch }) {
      dispatch('auth/logout')
    }
  },
  modules: {
    auth
  },
  plugins: [createPersistedState({
    key: 'fetisfy-admin'
  })],
  strict: process.env.NODE_ENV !== 'production'
})

export default store
