




































































































































import Vue from 'vue'
import { RequestImageInterface, RequestInterface } from '@/types/models/RequestInterface'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import RequestsService from '@/services/requests'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'RequestPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    headers: [
      { text: 'User', value: 'user.username', sortable: false },
      { text: 'Title', value: 'title', sortable: false },
      { text: 'Photo', value: 'photo', sortable: false },
      { text: 'Views', value: 'views', sortable: false },
      { text: 'Price', value: 'price', sortable: false },
      { text: 'Status', value: 'active', sortable: false },
      { text: 'Created', value: 'created', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    list: [] as RequestInterface[],
    showImgDialog: false,
    dialogImg: null as string | null,
    showRemoveDialog: false,
    selectedRequest: null as RequestInterface | null
  }),
  methods: {
    getDataFromAPI () {
      const filters = this.prepareFilters()
      RequestsService.get(this.tableOptions, filters)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    changeStatus (id: number, isBanned: boolean) {
      RequestsService.changeStatus(id, isBanned)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex(i => i.id === id)
            if (index > -1 && this.list[index]) {
              this.list[index].active = isBanned
            }
          }
        })
    },
    openRemoveDialog (item: RequestInterface) {
      if (item) {
        this.selectedRequest = item
        this.showRemoveDialog = true
      }
    },
    removeRequestPost (id: number) {
      RequestsService.remove(id)
        .then(res => {
          if (res.data.body) {
            this.getDataFromAPI()
          }
        })
        .finally(() => {
          this.showRemoveDialog = false
        })
    },
    showImageModal (url: string) {
      this.dialogImg = url
      this.showImgDialog = true
    },
    isImageFormat (media: RequestImageInterface) {
      if (!media || !media.type) {
        return false
      }
      return media.type.startsWith('image')
    },
    isVideoFormat (media: RequestImageInterface) {
      if (!media || !media.type) {
        return false
      }
      return media.type.startsWith('video')
    }
  }
})
