
















































































































































































import Vue from 'vue'
import { VFormType } from '@/types/vuetify/VFormType'
import { FiltersPricingInterface } from '@/types/api/FiltersPricingInterface'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import Languages from '@/utils/enum/Languages'
import SubscriptionPlansService from '@/services/subscriptionPlans'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'
import TranslationInterface from '@/types/models/TranslationInterface'
import { SubscriptionPlanInterface } from '@/types/models/SubscriptionPlanInterface'

export default Vue.extend({
  name: 'SubscriptionPlansPage',
  components: {
    DebounceSearchComponent
  },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    Languages: Languages,
    saveLoading: false,
    dialog: false,
    dialogDelete: false,
    list: [] as SubscriptionPlanInterface[],
    headers: [
      {
        text: 'Title',
        value: 'title'
      },
      {
        text: 'Initial price',
        value: 'initial_price'
      },
      {
        text: 'Initial period',
        value: 'initial_period'
      },
      {
        text: 'Recurring price',
        value: 'recurring_price'
      },
      {
        text: 'Recurring period',
        value: 'recurring_period'
      },
      {
        text: 'Discount',
        value: 'discount'
      },
      {
        text: 'Best',
        value: 'best'
      },
      {
        text: 'Fetishist',
        value: 'fetishist'
      },
      {
        text: 'Confidant',
        value: 'confidant'
      },
      {
        text: 'Premium',
        value: 'premium'
      },
      {
        text: 'CCBill flex form ID',
        value: 'flex_id'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ],
    editedIndex: -1,
    editedItem: undefined as SubscriptionPlanInterface | undefined,
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ color: [] }]
    ],
    translationFields: [
      'title',
      'price_text',
      'recurring_price_text',
      'full_price_text',
      'description',
      'first_payment_text',
      'full_price_info'
    ]
  }),
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form (): VFormType {
      return this.$refs.form as VFormType
    },
    translationList () {
      const translations = {} as TranslationInterface
      Languages.forEach(lang => {
        translations[lang] = null
      })
      return translations
    }
  },
  created () {
    this.editedItem = Object.assign({}, this.emptyItem())
  },
  methods: {
    getDataFromAPI () {
      this.tableLoading = true
      const params = {} as FiltersPricingInterface
      if (this.tableSearch) {
        params.search = this.tableSearch
      }
      SubscriptionPlansService.get(this.tableOptions, params)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    emptyItem () {
      return {
        id: null,
        flex_id: null,
        premium: false,
        initial_price: undefined,
        initial_period: undefined,
        recurring_price: undefined,
        recurring_period: undefined,
        pick: null,
        fetishist: false,
        confidant: false,
        best: false,
        discount: 0,
        title: Object.assign({}, this.translationList),
        price_text: Object.assign({}, this.translationList),
        recurring_price_text: Object.assign({}, this.translationList),
        full_price_text: Object.assign({}, this.translationList),
        description: Object.assign({}, this.translationList),
        first_payment_text: Object.assign({}, this.translationList),
        full_price_info: Object.assign({}, this.translationList)
      } as SubscriptionPlanInterface
    },
    setIndex (item: SubscriptionPlanInterface) {
      this.editedIndex = this.list.indexOf(item)
      const editedItem = Object.assign({}, item)
      this.translationFields.forEach(field => {
        if (editedItem[field]) {
          editedItem[field] = JSON.parse(item[field])
        } else {
          editedItem[field] = Object.assign({}, this.translationList)
        }
      })
      this.editedItem = editedItem
    },
    editItem (item: SubscriptionPlanInterface) {
      this.setIndex(item)
      this.dialog = true
    },
    sortUp (item: SubscriptionPlanInterface) {
      console.log(item)
    },
    sortDown (item: SubscriptionPlanInterface) {
      console.log(item)
    },
    save () {
      if (!this.form.validate()) {
        return
      }
      if (this.editedItem) {
        this.saveLoading = true
        const item = Object.assign({}, this.editedItem)
        this.translationFields.forEach(field => {
          if (item[field]) {
            item[field] = JSON.stringify(item[field])
          }
        })
        if (this.editedIndex > -1 && this.editedItem.id) {
          SubscriptionPlansService.edit(this.editedItem.id, item)
            .then(res => {
              if (res.data.body) {
                Object.assign(this.list[this.editedIndex], res.data.body)
              }
              this.close()
            })
            .finally(() => {
              this.saveLoading = false
            })
        } else {
          delete item.id
          SubscriptionPlansService.add(item)
            .then(res => {
              if (res.data.body) {
                this.reloadList()
              }
              this.close()
            })
            .finally(() => {
              this.saveLoading = false
            })
        }
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.emptyItem())
        this.editedIndex = -1
        if (this.form) {
          this.form.reset()
        }
      })
    },
    deleteItem (item: SubscriptionPlanInterface) {
      this.setIndex(item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      if (this.editedItem && this.editedItem.id) {
        SubscriptionPlansService.delete(this.editedItem.id)
          .then(() => {
            this.reloadList()
          })
          .finally(() => {
            this.closeDelete()
          })
      }
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.emptyItem())
        this.editedIndex = -1
      })
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  }
})
