

























































import Vue from 'vue'
import { EventInterface } from '@/types/models/EventInterface'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import EventsService from '@/services/events'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'EventsPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    headers: [
      { text: 'User', value: 'user.username', sortable: false },
      { text: 'Title', value: 'title', sortable: false },
      { text: 'Start', value: 'start', sortable: false },
      { text: 'End', value: 'end', sortable: false },
      { text: 'All day', value: 'allday', sortable: false },
      { text: 'Created', value: 'created', sortable: false }
    ],
    list: [] as EventInterface[]
  }),
  methods: {
    getDataFromAPI () {
      const filters = this.prepareFilters()
      EventsService.get(this.tableOptions, filters)
        .then(res => {
          if (res.data.body) {
            const items = res.data.body.items ? res.data.body.items : []
            if (items.length) {
              items.forEach((item: EventInterface) => {
                if (item.from_address) {
                  item.from_address = JSON.parse(item.from_address)
                }
                if (item.to_address) {
                  item.to_address = JSON.parse(item.to_address)
                }
              })
            }
            this.list = items
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    }
  }
})
