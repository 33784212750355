import Vue from 'vue'
import { DataOptions } from 'vuetify'
import { VDataTableType } from '@/types/vuetify/VDataTableType'
import { FiltersSearchInterface } from '@/types/api/FiltersSearchInterface'

export default Vue.mixin({
  data: () => ({
    tableLoading: false,
    tableOptions: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: true,
      page: 1,
      sortBy: [],
      sortDesc: []
    } as DataOptions,
    tableFooterProps: {
      'items-per-page-options': [],
      'items-per-page-text': null,
      'disable-items-per-page': true,
      'show-first-last-page': true,
      'show-current-page': true
    },
    tablePerPage: 10,
    tableTotal: 0,
    pages: 1,
    currentPage: 1,
    tableSearch: '',
    ID: null as number | null
  }),
  methods: {
    updateTableOptions () {
      (this as unknown as VDataTableType).getDataFromAPI()
    },
    getDataFromAPI (): void {
      // Should be replaced in current table component
    },
    reloadList (): void {
      const page = this.tableOptions.page
      if (page === 1) {
        this.getDataFromAPI()
      } else {
        this.tableOptions.page = 1
      }
    },
    updateSearch (search: string) {
      this.tableSearch = search
      this.reloadList()
    },
    setID (ID: number) {
      this.ID = ID
      // this.reloadList()
    },
    prepareFilters (): FiltersSearchInterface {
      this.tableLoading = true
      const filters = {} as FiltersSearchInterface
      if (this.tableSearch) {
        filters.search = this.tableSearch
      }
      if (this.ID) {
        filters.id = this.ID
      }
      return filters
    }
  }
})
