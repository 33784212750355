var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('debounce-search-component',{attrs:{"loading":_vm.tableLoading},on:{"update":_vm.updateSearch,"setID":_vm.setID},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("New One")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.editedItem)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"First name","rules":[function (v) { return !!v || 'First name is required'; }]},model:{value:(_vm.editedItem.first_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "first_name", $$v)},expression:"editedItem.first_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Last name","rules":[function (v) { return !!v || 'Last name is required'; }]},model:{value:(_vm.editedItem.last_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "last_name", $$v)},expression:"editedItem.last_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Username","rules":[function (v) { return !!v || 'Username is required'; }]},model:{value:(_vm.editedItem.username),callback:function ($$v) {_vm.$set(_vm.editedItem, "username", $$v)},expression:"editedItem.username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Email","rules":[
                                      function (v) { return !!v || 'Email is required'; },
                                      function (v) { return _vm.EmailRegex.test(v) || 'Email must be valid'; }
                                    ]},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"label":"Role","items":_vm.roles,"item-text":"name","item-value":"id","disabled":_vm.currentUser && _vm.currentUser.id === _vm.editedItem.id,"rules":[function (v) { return !!v || 'Role is required'; }]},model:{value:(_vm.editedItem.role_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "role_id", $$v)},expression:"editedItem.role_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Active","disabled":_vm.currentUser && _vm.currentUser.id === _vm.editedItem.id},model:{value:(_vm.editedItem.active),callback:function ($$v) {_vm.$set(_vm.editedItem, "active", $$v)},expression:"editedItem.active"}})],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.saveLoading},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)]},proxy:true}])},[(_vm.roles.length)?_c('v-data-table',{staticClass:"elevation-1 table-main",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.tablePerPage,"options":_vm.tableOptions,"hide-default-footer":"","server-items-length":_vm.tableTotal,"loading":_vm.tableLoading,"mobile-breakpoint":0,"page":_vm.currentPage,"header-props":{ sortIcon: 'mdi-chevron-down' }},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.updateTableOptions],"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Yes' : 'No')+" ")]}},{key:"item.online",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.online ? 'Yes' : 'No')+" ")]}},{key:"item.login_at",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.login_at))+" ")]}},{key:"item.actions",fn:function(ref){
                                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),(!item.is_admin)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,false,3638650358)}):_vm._e(),_c('v-row',{staticClass:"text-center align-center justify-center",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }