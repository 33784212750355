<template>
  <v-app-bar id="app-bar" absolute app color="#111114" flat height="75">
    <the-logo class="d-sm-none"/>
    <v-btn class="mr-3 hidden-xs-only" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">mdi-view-quilt</v-icon>
      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>
    <v-spacer />
    <v-menu v-if="showNotifications" bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge v-if="notifications.length" color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{notifications.length}}</span>
            </template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else>mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-list v-if="notifications.length" :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2 menu" min-width="0" text v-bind="attrs" v-on="on"/>
      </template>
      <v-list v-if="user" :tile="false" nav>
        <template v-slot:default>
          <div id="app-bar-menu" class="menu-list">
            <div class="menu-list-item">
              <v-list-item-title class="menu-list-name" to="profile">{{user.username}}</v-list-item-title>
            </div>
            <div class="menu-list-item">
              <v-list-item-title class="menu-list-logout" @click="logout">Log Out</v-list-item-title>
            </div>
          </div>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapState } from 'vuex'
import { VHover, VListItem } from 'vuetify/lib'
import debounce from '@/utils/debounce'
import TheLogo from '@/components/core/TheLogo'

export default {
  name: 'CorePrivateAppBar',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TheLogo,
    AppBarItem: {
      render (h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text secondary elevation-12': hover
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs
                }
              }, this.$slots.default)
            }
          }
        })
      }
    }
  },
  data: () => ({
    showNotifications: false, // TODO: remove it when notifications will be implemented
    notifications: []
  }),
  computed: {
    ...mapState({
      drawer: state => state.drawer,
      user: state => state.auth.user
    })
  },
  methods: {
    setDrawer (val) {
      this.$store.dispatch('setDrawer', val)
    },
    logout () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login')
      })
    }
  },
  created () {
    this.debounceHandler = debounce(this.reloadList, 1000)
  }
}
</script>
<style scoped lang="scss">
  #app-bar {
    .menu {
      position: relative;
      background-color: #222229;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 2px solid transparent;

      &[aria-expanded=true] {
        border-color: #FFFFFF;
        background-color: #111114;
      }

      @include media-phone {
        width: 40px;
        height: 40px;
      }

      &:before {
        display: none;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-image: url(../../../assets/icons/menu-icon.svg);
        background-size: contain;
        width: 30px;
        height: 21px;

        @include media-phone {
          width: 24px;
          height: 17px;
        }
      }
    }
  }
  #app-bar-menu.menu-list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: #FFFFFF;
    padding: 20px;
    margin-top: 10px;
    border-radius: 15px;

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      right: 20px;
      border-style: solid;
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent #FFFFFF transparent;

      @include media-phone {
        right: 14px;
      }
    }

    .menu-list-name {
      font: normal normal bold 18px/27px Poppins;
      color: #F70F0F;
      text-transform: capitalize;

      @include media-phone {
        font-size: 16px;
      }
    }
    .menu-list-logout {
      font: normal normal normal 16px/20px Lexend Deca;
      color: #222229;
      padding-left: 30px;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        display: block;
        mask-image: url(../../../assets/icons/logout.svg);
        background-color: #222229;
        mask-size: contain;
      }

      @include media-phone {
        font-size: 14px;
      }
    }
  }
</style>
