







import Vue from 'vue'
import { mapActions } from 'vuex'
import Media from '@/utils/enum/Media'

export default Vue.extend({
  name: 'App',
  data () {
    return {
      timerID: 0
    }
  },
  mounted () {
    this.setMedia()
    window.addEventListener('resize', this.resizeDebounced)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeDebounced)
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    resizeDebounced () {
      clearTimeout(this.timerID)
      this.timerID = setTimeout(() => {
        this.setMedia()
      }, 500)
    },
    setMedia () {
      const isMobile = window.innerWidth <= Media.Mobile
      if (this.$store.state.mobile !== isMobile) {
        this.$store.dispatch('setMobile', isMobile)
      }
    }
  },
  onIdle () {
    if (this.$store.state.auth.isLoggedIn) {
      this.logout()
    }
  }
})
