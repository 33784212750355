import { DataOptions } from 'vuetify'
import { DataPaginationParamsInterface } from '@/types/vuetify/DataPaginationParamsInterface'

export function prepareParams (options: DataOptions) {
  const { sortBy, sortDesc, page, itemsPerPage } = options
  let params = ''
  if (page) {
    params += `page=${page}&`
  }
  if (itemsPerPage) {
    params += `limit=${itemsPerPage}&`
  }
  if (sortBy && sortBy.length) {
    params += `sort=${sortBy[0]}&`
  }
  if (sortDesc && sortDesc.length) {
    const direction = sortDesc[0] ? 'asc' : 'desc'
    params += `direction=${direction}&`
  }
  if (params.length) {
    params = '?' + params
    params = params.slice(0, -1)
  }
  return params
}

export function transformParams (options: DataOptions) {
  const { sortBy, sortDesc } = options
  const params = {
    page: options.page
  } as DataPaginationParamsInterface
  if (options.itemsPerPage) {
    params.limit = options.itemsPerPage
  }
  if (sortBy && sortBy.length) {
    params.sort = sortBy[0]
  }
  if (sortDesc && sortDesc.length) {
    params.direction = sortDesc[0] ? 'asc' : 'desc'
  }
  return params
}

export default { prepareParams, transformParams }
